<template>
  <div class="codedemo-container">
    <div class="result-demo">{{ product.demoCode }}</div>
    <div class="teacher-video-container">
      <div class="teacher-video">
        <iframe
          width="100%"
          height="auto"
          src="https://www.youtube.com/embed/cj_5limKKWY?si=J7KRmOrbatAbAE1K"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>

    <div class="codedemo">
      <button
        class="btn btn-primary m-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        Code Inspector
      </button>

      <ul
        class="nav nav-underline justify-content-around"
        id="myTab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active m-0"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home-tab-pane"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            HTML
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link m-0"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile-tab-pane"
            type="button"
            role="tab"
            aria-controls="profile-tab-pane"
            aria-selected="false"
          >
            CSS
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link m-0"
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact-tab-pane"
            type="button"
            role="tab"
            aria-controls="contact-tab-pane"
            aria-selected="false"
          >
            Javascript
          </button>
        </li>
      </ul>
      <div class="tab-content overflow-scroll" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home-tab-pane"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabindex="0"
        >
          <pre>
            <code class="language-html" v-text="product.demoCode.html"></code>
          </pre>
        </div>
        <div
          class="tab-pane fade"
          id="profile-tab-pane"
          role="tabpanel"
          aria-labelledby="profile-tab"
          tabindex="0"
        >
          <pre>
            <code class="language-css" v-text="product.demoCode.css"></code>
          </pre>
        </div>
        <div
          class="tab-pane fade"
          id="contact-tab-pane"
          role="tabpanel"
          aria-labelledby="contact-tab"
          tabindex="0"
        >
          <pre>
            <code class="language-javascript" v-text="product.demoCode.js"></code>
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { products } from "../temp-data";
import Prism from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

import "js-beautify/js/lib/beautify.js";
import "js-beautify/js/lib/beautify-css.js";
import "js-beautify/js/lib/beautify-html.js";

export default {
  name: "CodePen",
  data() {
    return {
      product: products.find(
        (product) => product.id === this.$route.params.productId
      ),
      accordionCollapseElem: null,
    };
  },
  mounted() {
    // Highlight code using Prism.js
    Prism.highlightAll();
    // Execute the JavaScript code to set up the demo
    this.setupDemo();
    // this.makeAccordianResponsive();
  },
  methods: {
    setupDemo() {
      let demoCode = this.product.demoCode;
      // Add styles for demo
      let demoStyle = document.createElement("style");
      demoStyle.innerHTML = demoCode.css;
      document.head.appendChild(demoStyle);
      //Add html for demo
      let resultDemo = document.querySelector(".result-demo");
      resultDemo.innerHTML = demoCode.html;
      // Execute the JavaScript code to set up the demo
      let demoScript = document.createElement("script");
      demoScript.innerHTML = demoCode.js;
      document.head.appendChild(demoScript);
    },
    // makeAccordianResponsive() {
    //   this.accordionCollapseElem = document.querySelectorAll(
    //     ".accordion .collapse"
    //   );
    //   this.accordionCollapseElem.forEach(function (item) {
    //     if (item.classList.contains("show")) {
    //       item.parentElement.classList.add("show");
    //     }
    //     item.addEventListener("show.bs.collapse", function () {
    //       this.parentElement.classList.add("show");
    //     });
    //     item.addEventListener("hide.bs.collapse", function () {
    //       this.parentElement.classList.remove("show");
    //     });
    //   });
    // },
  },
};
</script>

<style scoped>
#myTabContent {
  height: 100%;
}
/* .accordion {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
} */
/* .accordion-button {
  margin: 0;
} */
.codedemo-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "content video"
    "content code";
  overflow: hidden;
}
@media (max-width: 576px) and (orientation: portrait) {
  .codedemo-container {
    display: flex;
    flex-direction: column;
  }
  /* .codedemo-container {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(1fr, auto) minmax(0, 1fr);
    grid-template-areas:
      "content"
      "code";
    overflow: hidden;
  } */

  .result-demo {
    height: 50%;
  }
  .codedemo {
    background: black;
    display: flex;
    flex-direction: column;
    border-left: 1px solid black;
    width: 100%;
    height: 50%;
  }
  .teacher-video-container {
    grid-area: none;
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 25vw;
    min-width: 150px;
    height: auto;
  }
}

.teacher-video-container {
  grid-area: video;
  box-shadow: 0 0 6px rgb(0 0 0 / 50%);
}
.teacher-video {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.teacher-video::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.teacher-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.demo-label {
  border-bottom: 1px solid white;
}
.demo-label span {
  color: black;
  background-color: white;
  padding: 0.2rem;
  border-radius: 0 5px 0 0;
}
.result-demo {
  grid-area: content;
  background: white;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* .codedemo > * {
  flex: 1 1 0px;
  height: 33%;
} */
pre[class*="language-"],
code[class*="language-"] {
  white-space: normal;
  overflow: auto;
  word-break: break-word;
}
pre {
  background-color: transparent;
}
code {
  text-shadow: none;
  color: white;
}
</style>
