export const products = [
  {
    id: "click-to-toggle",
    name: "Click to toggle",
    fileName: "click-to-toggle.html",
    description: "",
    demoCode: {
      html: `<div class='basic-block'>Click me to change my style</div><div class='basic-block'>Click me to change my style</div>`,
      css: `.basic-block {width: 200px;height: 200px; background-color: grey; margin: 1rem;
      }.basic-block.clicked {background-color: green;}`,
      js: `
      var basicDivs = document.querySelectorAll('.basic-block'); basicDivs.forEach(function(div){div.addEventListener('click', function(){this.classList.toggle('clicked');});});`,
    },
  },
  {
    id: "click-to-animate",
    name: "Click to animate",
    fileName: "click-to-animate.html",
    description: "",
    demoCode: {
      html: `<div class="block-container">
      <div class="block"></div>
    </div>
    <div class="block-container">
      <div class="block"></div>
    </div>`,
      css: `.block-container {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
      } 
      .block {
        width: 100%;
        height: 100%;
        background-color: yellow;
        transition: transform 0.5s ease;
      }
      .scale {
        transform: scale(.5)
      }`,
      js: `var divs = document.querySelectorAll('.block');
  
      divs.forEach(function(div){
        div.addEventListener('click', function(){
          this.classList.toggle('scale');
        });
      });`,
    },
  },
  {
    id: "Observable_api",
    name: "ObservableAPI",
    fileName: "observableAPI.html",
    description: "",
    uiConfig: {
      showFloatingVideo: false,
      showCodePen: false,
    },
    demoCode: {
      html: `<div class="observable"></div>
      <div class="observable"></div>
      <div class="observable"></div>
      <div class="observable"></div>
      <div class="observable"></div>
      <div class="observable"></div>
      <div class="observable"></div>
      <div class="observable"></div>`,
      css: `.observable {
        margin-top: 120px;
        margin-bottom: 120px; 
        width: 200px;
        height: 200px;
        min-height: 200px;
        min-width: 200px;
        background-color: black;
        opacity: 0;
        transition: opacity 1s ease;
    }
    .animate-opacity {
        opacity: 1;
    }`,
      js: `function observeElementWithClass(className) {
        var elements = document.querySelectorAll('.' + className);

        var observer = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                entry.target.classList.add("animate-opacity");
            } else {
                entry.target.classList.remove("animate-opacity");
            }
            });
        }, { threshold: 0.8 });

        elements.forEach(function(element) {
            observer.observe(element);
        });
        }

        observeElementWithClass("observable");`,
    },
  },
  {
    id: "event-coordinates",
    name: "Event Coordinates",
    fileName: "event-coordinates.html",
    description:
      "Create elements just by clicking! Use this pattern for things like drawing on screen. Your imagination is the only limit! Makes use of math expressions 'Math.floor' and 'Math.random'. Also, mouse event properties 'clientX' and 'clientY'. Also, the Array property 'length'.  ",
    demoCode: {
      html: `<div class="canvas"></div>`,
      css: `
      .canvas {
        width: 100%;
        height: 100%;
      }
      .created-element {
        position: absolute;
        background-color: #3498db;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }`,
      js: ` //store an array of colors
      //we'll pick a color randomly to set the background of our newly created elements.
      var colors = ['#3498db', '#e74c3c', '#2ecc71', '#f1c40f', '#9b59b6', '#e67e22', '#1abc9c', '#34495e'];

      //add an event listenr of 'DOMContentLoad' to ensure the elements have loaded into the DOM
      //We don't technically need this event listener because the script is ready after the elements are loaded
     
        var canvas = document.querySelector('.canvas');
          canvas.addEventListener('click', function (event) {
            
              // Create a new DOM element
              var element = document.createElement('div');
              //sets a class name for the element
              element.className = 'created-element';

              // Set the element's position to where the click occurred
              //Instead of positioning the element by it's top/left corner center the element using math  
              //RESEARCH: what is the difference between clientX/Y and x/y
              element.style.left = (event.x - 25) + 'px'; // Subtract half of the width
              element.style.top = (event.y - 25) + 'px'; // Subtract half of the height

              // Select a color from the array using a randomly generated index number
              //Ensure the index number is a whole integer
              //Ensure the index number is no higher than the number of items in the array 
              var randomColor = colors[Math.floor(Math.random() * colors.length)];


              //Set the properties style > backgroundColor to the randomly selected color hexcode
              element.style.backgroundColor = randomColor;

              // Append the element to the body
              canvas.appendChild(element);
          });
      `,
    },
  },
  {
    id: "one-at-a-time",
    name: "One state-change at a time",
    fileName: "one-at-a-time.html",
    description:
      "Change the state (styles) of any element that is clicked. Ensure that only one element changes state at a time. Makes use of DOM methods querySelector() and classList. Also, the Array methods forEach().",
    demoCode: {
      html: `        <!-- these element will get the '.basic-block.clicked' class when clicked -->
      <div class="basic-block"></div>
      <div class="basic-block"></div>
      <div class="basic-block"></div>
      <div class="basic-block"></div>`,
      css: ` 
      .basic-block {
        width: 200px;
        height: 200px;
        background-color: black;
        margin: 1rem;
      }
      /* Any element with this class will have a green background color */ 
      .clicked {
        background-color: green;
      }`,
      js: `//gets all of the divs on the page
      //it returns a nodeList which we can treat as an arrays
      var divs = document.querySelectorAll('.basic-block');
      
      //iterates over each div element in the nodeList (array)
      divs.forEach(div => {
        console.log(div);
        //adds an 'click' even listener to each div
        div.addEventListener('click', () => {
          //when clicked, ensures that no div elements have the 'clicked' class
          divs.forEach(div => {
            div.classList.remove('clicked');
          });
          //then adds the 'clicked' class to only the the element that was clicked on
          div.classList.add('clicked');
        });
      }); //effectively, this entire function ensures that only one element changes state at a time each time an element is clickeds.`,
    },
  },
  {
    id: "toggle-state",
    name: "Toggle State",
    fileName: "toggle-state.html",
    description:
      "This technique can change the content on the page without actually loading a new page. You can use this pattern to show/hide different elements. To do so, the event binded to each button element will change the css on another specififed element. This pattern makes use of the DOM methods addEventListener as well as classList() and it's add/remove properties. ",
    demoCode: {
      html: `      <!-- these button will show/hide their respective element-->
      <div>
        <button id="home-button">Home</button>
        <button id="work-button">Work</button>
      </div>
      <!-- these elements (and their children) will show/hide when the corresponding button is clicked -->
      <section id="home">
        <div>H</div>
        <div>O</div>
        <div>M</div>
        <div>E</div>
      </section>
      <section id="work" class="hide">
        <div>W</div>
        <div>O</div>
        <div>R</div>
        <div>K</div>
      </section>`,
      css: `      section > div {
        width: 200px;
        height: 200px;
        background-color: white;
      } 
      .basic-block.clicked {
        background-color: green;
      }
      section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        color: black;
      }
      section > div {
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 150px;
      }
      .hide {
        display: none;
      }`,
      js: `    //get the buttons that will trigger each state change
      var workButton = document.querySelector('#work-button');
      var homeButton = document.querySelector('#home-button');
  
      //get the elements that will change state when the corresponding button is clicked
      var homeDiv = document.querySelector('#home');
      var workDiv = document. querySelector('#work');
      
      //add the 'click' event listener that triggers the corresponding function
      workButton.addEventListener('click', showWorkAndHideHome);
      homeButton.addEventListener('click', showHomeAndHideWork);
  
      //ensures that anytime this function runs, only the 'home' element (and it's children) are hidden
      function showWorkAndHideHome () {
        workDiv.classList.remove('hide');
        homeDiv.classList.add('hide');
      }
  
      //ensures that anytime this function runs, only the 'work' element (and it's children) are hidden
      function showHomeAndHideWork () {
        workDiv.classList.add('hide');
        homeDiv.classList.remove('hide');
      }`,
    },
  },
  {
    id: "create-html-with-data",
    name: "Create HTML with data",
    fileName: "create-html-with-data.html",
    description:
      "Dynamically create html templates based on a dataset. Inject data into each html template. This examples makes us of Array methods such as forEach() and find(). Also, DOM methods such as createElement() and appendChild().",
    demoCode: {
      html: `      <!-- this button triggers the function that creates the articles -->
      <button id="createArticles">Create Articles</button>
      <!-- this element is where the function appends each article -->
      <section id="articles"></section>`,
      css: `      button#createArticles {
        margin: 2rem;
        padding: 1rem;
        border-radius: 0;
      }
      article {
        display: block;
        border: 1px solid black;
        border-radius: 15px;
        padding: 1rem;
        text-align: left;
        color: black;
      }`,
      js: `//the content for each article is stored in this array
      var data = [
        {
          id: "article-1",
          h2: "Article One",
          p: "Ut elit veniam id do in cillum deserunt elit officia esse veniam."
        },
        {
          id: "article-2",
          h2: "Article Two",
          p: "Sint duis aute reprehenderit mollit labore sunt ut."
        },
        {
          id: "article-3",
          h2: "Article Three",
          p: "Ea aliquip occaecat nostrud irure dolore veniam in nostrud quis reprehenderit nisi."
        }
      ];
      
      //this function creates html for each object in the array
      //and inserts that data from each object into that html
      function createHtmlForData(){
  
        //this loop iterates over each object in the 'data' array
        data.forEach(function(item){    
          var itemID = item.id;
          var itemP = item.p;
          var itemH2 = item.h2;
  
          //creates an article element and sets the id to the object's 'id' property
          var itemElement = document.createElement("article");
          itemElement.setAttribute("id", itemID);
  
          //creates an h2 element and sets the text to the object's 'h2' property
          var h2Element = document.createElement("h2");
          h2Element.innerText = itemH2; 
          
  
          //creates a p element and sets the text to the object's 'p' property
          var pElement = document.createElement("p");
          pElement.innerText = itemP; 
  
          //appends the 'h2' and 'p' elements to the article element
          itemElement.append(h2Element);
          itemElement.append(pElement);
  
          //appends each article element to the '#articles' section
          var articlesContainer = document.querySelector("#articles");
          articlesContainer.append(itemElement);
        });
      };
  
      //adds an event listener to the button that triggers the above 'createHtmlForData' function
      var createArticlesButton = document.querySelector("#createArticles");
      createArticlesButton.addEventListener('click',createHtmlForData);`,
    },
  },
  {
    id: "drag-and-drop",
    name: "Drag and drop",
    fileName: "drag-and-drop.html",
    description:
      "Move elements around the page using the 'drag' eventListeners. This pattern makes use of the DOM methods addEventListener() and getBoundingClientRect(). Also, the mouse event properties clientX and clientY. This one is a little complex because drag and drop uses several related event listeners. Also, because we need to calculate the new position of the drag and dropped element.",
    demoCode: {
      html: `        <div id="drag-area">
      <div class="draggable" id="draggable1" draggable="true"></div>
      <div class="draggable" id="draggable2" draggable="true"></div>
    </div>`,
      css: `#drag-area {
        width: 100%;
        height: 100%;
      }
      .draggable {
          width: 200px;
          height: 200px;
          top: 250px;
          background-color: black;
          color: #fff;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
          position: absolute;
      }
      #draggable1 {
        left: 300px;
      }
      #draggable2 {
        left: 600px;
      }`,
      js: `    // Get the elements we want to drag
      var draggables = document.querySelectorAll('.draggable');
  
      // To be used to store the element while being dragged
      var draggedElement = null;
  
      // Loop through the nodeList (array) of draggable elements
      draggables.forEach(draggable => {
        //for each element in the nodeList (array) add a 'dragstart' event listener
        draggable.addEventListener('dragstart', (e) => {
          //get the id of the dragged element to track it
          e.dataTransfer.setData('text/plain', draggable.id);
          //store the element while being dragged
          draggedElement = draggable;
  
          //use the method 'getBoundingClientRect()'
          //this methods gets data about an element's position and size
          const rect = draggable.getBoundingClientRect();
          console.log(rect);
          // Store the initial offset from the mouse pointer to the element's top-left corner
          offsetX = e.clientX - rect.left;
          offsetY = e.clientY - rect.top;
  
          // Sets the position of the draggable element based on the position of the cursor subtracted by the offset
          //This math positions the element's center over the cursor position rather than the element's top/left corner
          draggable.style.left = e.clientX - offsetX + 'px';
          draggable.style.top = e.clientY - offsetY + 'px';
      });
  
      //listens for when the user stops dragging
      draggable.addEventListener('dragend', () => {
          //set the element back to null once it's no longer dragged
          draggedElement = null;
      });
  });
  
  // Add an event listener to the body for 'dragover'
  var draggableArea = document.querySelector('#drag-area');
  draggableArea.addEventListener('dragover', (e) => {
      //The default behavior of any element doesn't allow for dragging
      //This stops this default behavior, to allow dragging during a 'dragover' event
      e.preventDefault();
  });
  
  //Add an event listener to the body for when the user lets go of the drag
  draggableArea.addEventListener('drop', (e) => {
      //again we must stop the default behavior
      //the default behavior does not allow for drag and drop
      e.preventDefault();
  
      //check to make sure that the draggedElement is not still null
      //the dragged element won't be null any time it's dragged
      if (draggedElement) {
        //calculate a new position as centered over the mouse cursor when dropped
        const offsetX = draggedElement.offsetWidth / 2;
        const offsetY = draggedElement.offsetHeight / 2;
        const x = e.clientX - offsetX;
        const y = e.clientY - offsetY;
        
        //position the new element using the calculations above
        //update the elements css properties to do so
        draggedElement.style.left = x + 'px';
        draggedElement.style.top = y + 'px';
  
        //Once the position is calculated and set, we can append the dragged element back into the body element
        draggableArea.appendChild(draggedElement);
        draggedElement = null;
      }
  });`,
    },
  },
];
