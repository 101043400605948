<template>
  <div
    id="mlb2-9947213"
    class="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-9947213"
  >
    <div class="ml-form-align-center">
      <div class="ml-form-embedWrapper embedForm">
        <div class="ml-form-embedBody ml-form-embedBodyDefault row-form">
          <form
            class="ml-block-form"
            action="https://assets.mailerlite.com/jsonp/720065/forms/106748979174180276/subscribe"
            data-code=""
            method="post"
            target="_blank"
          >
            <div class="ml-form-formContent">
              <div class="ml-form-fieldRow ml-last-item">
                <div
                  class="ml-field-group ml-field-email ml-validate-email ml-validate-required"
                >
                  <!-- input -->
                  <input
                    aria-label="email"
                    aria-required="true"
                    type="email"
                    class="form-control"
                    data-inputmask=""
                    name="fields[email]"
                    placeholder="Email"
                    autocomplete="email"
                  />
                  <!-- /input -->

                  <!-- textarea -->

                  <!-- /textarea -->

                  <!-- select -->

                  <!-- /select -->

                  <!-- checkboxes -->

                  <!-- /checkboxes -->

                  <!-- radio -->

                  <!-- /radio -->

                  <!-- countries -->

                  <!-- /countries -->
                </div>
              </div>
            </div>

            <!-- Privacy policy -->

            <!-- /Privacy policy -->

            <input type="hidden" name="ml-submit" value="1" />

            <div class="ml-form-embedSubmit">
              <button type="submit" class="primary">LEARN MORE</button>

              <button
                disabled="disabled"
                style="display: none"
                type="button"
                class="loading"
              >
                <div class="ml-form-embedSubmitLoad"></div>
                <span class="sr-only">Loading...</span>
              </button>
            </div>
            <div class="fine-print">
              <span>I will never spam you or share your information</span>
            </div>

            <input type="hidden" name="anticsrf" value="true" />
          </form>
        </div>

        <div class="ml-form-successBody row-success" style="display: none">
          <div class="ml-form-successContent">
            <h4>Hey, there!</h4>

            <p>Welcome to our band of misfits! More to come soon.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.fine-print {
  font-size: 0.75rem;
}
.ml-block-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ml-form-embedSubmitLoad {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.g-recaptcha {
  transform: scale(1);
  -webkit-transform: scale(1);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.ml-form-embedSubmitLoad:after {
  content: " ";
  display: block;
  width: 11px;
  height: 11px;
  margin: 1px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #ffffff #ffffff #ffffff transparent;
  animation: ml-form-embedSubmitLoad 1.2s linear infinite;
}
@keyframes ml-form-embedSubmitLoad {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#mlb2-9947213.ml-form-embedContainer {
  box-sizing: border-box;
  display: table;
  margin: 0 auto;
  position: static;
  width: 100% !important;
}
#mlb2-9947213.ml-form-embedContainer h4,
#mlb2-9947213.ml-form-embedContainer p,
#mlb2-9947213.ml-form-embedContainer span,
#mlb2-9947213.ml-form-embedContainer button {
  text-transform: none !important;
  letter-spacing: normal !important;
}
#mlb2-9947213.ml-form-embedContainer .ml-form-embedWrapper {
  border-width: 0px;
  border-color: transparent;
  border-radius: 4px;
  border-style: solid;
  box-sizing: border-box;
  display: inline-block !important;
  margin: 0;
  padding: 0;
  position: relative;
}
#mlb2-9947213.ml-form-embedContainer .ml-form-embedWrapper.embedPopup,
#mlb2-9947213.ml-form-embedContainer .ml-form-embedWrapper.embedDefault {
  width: 400px;
}
#mlb2-9947213.ml-form-embedContainer .ml-form-embedWrapper.embedForm {
  /* max-width: 400px; */
  width: 100%;
}
#mlb2-9947213.ml-form-embedContainer .ml-form-align-left {
  text-align: left;
}
#mlb2-9947213.ml-form-embedContainer .ml-form-align-center {
  text-align: center;
}
#mlb2-9947213.ml-form-embedContainer .ml-form-align-default {
  display: table-cell !important;
  vertical-align: middle !important;
  text-align: center !important;
}
#mlb2-9947213.ml-form-embedContainer .ml-form-align-right {
  text-align: right;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedHeader
  img {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: auto;
  margin: 0 auto !important;
  max-width: 100%;
  width: undefinedpx;
}
/* #mlb2-9947213.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-successBody {
  padding: 20px 20px 0 20px;
} */
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody.ml-form-embedBodyHorizontal {
  padding-bottom: 0;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedContent,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-successBody
  .ml-form-successContent {
  text-align: left;
  margin: 0 0 20px 0;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedContent
  h4,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-successBody
  .ml-form-successContent
  h4 {
  color: white;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 400;
  margin: 0 0 10px 0;
  text-align: left;
  word-break: break-word;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedContent
  p,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-successBody
  .ml-form-successContent
  p {
  color: white;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 10px 0;
  text-align: left;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedContent
  ul,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedContent
  ol,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-successBody
  .ml-form-successContent
  ul,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-successBody
  .ml-form-successContent
  ol {
  color: white;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedContent
  ol
  ol,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-successBody
  .ml-form-successContent
  ol
  ol {
  list-style-type: lower-alpha;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedContent
  ol
  ol
  ol,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-successBody
  .ml-form-successContent
  ol
  ol
  ol {
  list-style-type: lower-roman;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedContent
  p
  a,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-successBody
  .ml-form-successContent
  p
  a {
  color: white;
  text-decoration: underline;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-block-form
  .ml-field-group {
  text-align: left !important;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-block-form
  .ml-field-group
  label {
  margin-bottom: 5px;
  color: #333333;
  font-size: 14px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  display: inline-block;
  line-height: 20px;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedContent
  p:last-child,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-successBody
  .ml-form-successContent
  p:last-child {
  margin: 0;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  form {
  margin: 0;
  width: 100%;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-formContent,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow {
  margin: 0 0 20px 0;
  width: 100%;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow {
  /* float: left; */
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-formContent.horozintalForm {
  margin: 0;
  padding: 0 0 20px 0;
  width: 100%;
  height: auto;
  /* float: left; */
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow {
  margin: 0 0 10px 0;
  width: 100%;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow.ml-last-item {
  margin: 0;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow.ml-formfieldHorizintal {
  margin: 0;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  input {
  background-color: #ffffff !important;
  color: #333333 !important;
  border-color: #cccccc;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px !important;
  height: auto;
  line-height: 21px !important;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 10px 10px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  max-width: 100% !important;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  input::-webkit-input-placeholder,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  input::-webkit-input-placeholder {
  color: #333333;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  input::-moz-placeholder,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  input::-moz-placeholder {
  color: #333333;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  input:-ms-input-placeholder,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  input:-ms-input-placeholder {
  color: #333333;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  input:-moz-placeholder,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  input:-moz-placeholder {
  color: #333333;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  textarea,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  textarea {
  background-color: #ffffff !important;
  color: #333333 !important;
  border-color: #cccccc;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px !important;
  height: auto;
  line-height: 21px !important;
  margin-bottom: 0;
  margin-top: 0;
  padding: 10px 10px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  max-width: 100% !important;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-radio
  .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-radio
  .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-checkbox
  .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-checkbox
  .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedPermissions
  .ml-form-embedPermissionsOptionsCheckbox
  .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-interestGroupsRow
  .ml-form-interestGroupsRowCheckbox
  .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  .label-description::before {
  border-color: #cccccc !important;
  background-color: #ffffff !important;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  input.custom-control-input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin-top: 5px;
  margin-left: -1.5rem;
  overflow: visible;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-checkbox
  .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-checkbox
  .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedPermissions
  .ml-form-embedPermissionsOptionsCheckbox
  .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-interestGroupsRow
  .ml-form-interestGroupsRowCheckbox
  .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  .label-description::before {
  border-radius: 4px !important;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  input[type="checkbox"]:checked
  ~ .label-description::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedPermissions
  .ml-form-embedPermissionsOptionsCheckbox
  input[type="checkbox"]:checked
  ~ .label-description::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-interestGroupsRow
  .ml-form-interestGroupsRowCheckbox
  input[type="checkbox"]:checked
  ~ .label-description::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedPermissions
  .ml-form-embedPermissionsOptionsCheckbox
  input[type="checkbox"]:checked
  ~ .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-interestGroupsRow
  .ml-form-interestGroupsRowCheckbox
  input[type="checkbox"]:checked
  ~ .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  input[type="checkbox"]:checked
  ~ .label-description::before {
  border-color: #000000 !important;
  background-color: #000000 !important;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-radio
  .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-radio
  .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-radio
  .custom-control-label::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-radio
  .custom-control-label::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-checkbox
  .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-checkbox
  .custom-control-label::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-checkbox
  .custom-control-label::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-checkbox
  .custom-control-label::after {
  top: 2px;
  box-sizing: border-box;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedPermissions
  .ml-form-embedPermissionsOptionsCheckbox
  .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedPermissions
  .ml-form-embedPermissionsOptionsCheckbox
  .label-description::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  .label-description::after {
  top: 0px !important;
  box-sizing: border-box !important;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  .label-description::after {
  top: 0px !important;
  box-sizing: border-box !important;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-interestGroupsRow
  .ml-form-interestGroupsRowCheckbox
  .label-description::after {
  top: 0px !important;
  box-sizing: border-box !important;
  position: absolute;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-interestGroupsRow
  .ml-form-interestGroupsRowCheckbox
  .label-description::before {
  top: 0px !important;
  box-sizing: border-box !important;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .custom-control-label::before {
  position: absolute;
  top: 4px;
  left: -1.5rem;
  display: block;
  width: 16px;
  height: 16px;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #adb5bd solid 1px;
  border-radius: 50%;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .custom-control-label::after {
  position: absolute;
  top: 2px !important;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedPermissions
  .ml-form-embedPermissionsOptionsCheckbox
  .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-interestGroupsRow
  .ml-form-interestGroupsRowCheckbox
  .label-description::before,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  .label-description::before {
  position: absolute;
  top: 4px;
  left: -1.5rem;
  display: block;
  width: 16px;
  height: 16px;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #adb5bd solid 1px;
  border-radius: 50%;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedPermissions
  .ml-form-embedPermissionsOptionsCheckbox
  .label-description::after {
  position: absolute;
  top: 0px !important;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  .label-description::after {
  position: absolute;
  top: 0px !important;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .custom-radio
  .custom-control-label::after {
  background: no-repeat 50%/50% 50%;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .custom-checkbox
  .custom-control-label::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedPermissions
  .ml-form-embedPermissionsOptionsCheckbox
  .label-description::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-interestGroupsRow
  .ml-form-interestGroupsRowCheckbox
  .label-description::after,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  .label-description::after {
  background: no-repeat 50%/50% 50%;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-control,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-radio
  .custom-control-input,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-radio
  .custom-control-input,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-checkbox
  .custom-control-input,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-checkbox
  .custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  box-sizing: border-box;
  padding: 0;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-radio
  .custom-control-label,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-radio
  .custom-control-label,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-checkbox
  .custom-control-label,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-checkbox
  .custom-control-label {
  color: #ffffff;
  font-size: 12px !important;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  line-height: 22px;
  margin-bottom: 0;
  position: relative;
  vertical-align: top;
  font-style: normal;
  font-weight: 700;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-fieldRow
  .custom-select,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  .custom-select {
  background-color: #ffffff !important;
  color: #ffffff !important;
  border-color: #cccccc;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px !important;
  line-height: 20px !important;
  margin-bottom: 0;
  margin-top: 0;
  padding: 10px 28px 10px 12px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  max-width: 100% !important;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  background: url("https://assets.mlcdn.com/ml/images/default/dropdown.svg")
    no-repeat right 0.75rem center/8px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow {
  height: auto;
  width: 100%;
  /* float: left; */
}
.ml-form-formContent.horozintalForm
  .ml-form-horizontalRow
  .ml-input-horizontal {
  width: 70%;
  /* float: left; */
}
.ml-form-formContent.horozintalForm
  .ml-form-horizontalRow
  .ml-button-horizontal {
  width: 30%;
  /* float: left; */
}
.ml-form-formContent.horozintalForm
  .ml-form-horizontalRow
  .ml-button-horizontal.labelsOn {
  padding-top: 25px;
}
.ml-form-formContent.horozintalForm .ml-form-horizontalRow .horizontal-fields {
  box-sizing: border-box;
  /* float: left; */
  padding-right: 10px;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  input {
  background-color: #ffffff;
  color: #ffffff;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  overflow-y: initial;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  button {
  background-color: #000000 !important;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  box-shadow: none;
  color: #ffffff !important;
  cursor: pointer;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 20px;
  margin: 0 !important;
  padding: 10px !important;
  width: 100%;
  height: auto;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-horizontalRow
  button:hover {
  background-color: #333333 !important;
  border-color: #333333 !important;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin-top: 5px;
  margin-left: -1.5rem;
  overflow: visible;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  .label-description {
  color: #000000;
  display: block;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 12px;
  text-align: left;
  margin-bottom: 0;
  position: relative;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  label {
  font-weight: normal;
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
  min-height: 24px;
  padding-left: 24px;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  label
  a {
  color: #000000;
  text-decoration: underline;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  label
  p {
  color: #000000 !important;
  font-family: "Open Sans", Arial, Helvetica, sans-serif !important;
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 18px !important;
  padding: 0 !important;
  margin: 0 5px 0 0 !important;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow
  label
  p:last-child {
  margin: 0;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedSubmit {
  margin: 0 0 5px 0;
  /* float: left; */
  width: 50%;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedSubmit
  button {
  background-color: #9d21b0 !important;
  border: none !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  color: #ffffff !important;
  cursor: pointer;
  font-family: "Open Sans", Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  height: auto;
  padding: 10px !important;
  width: 100% !important;
  box-sizing: border-box !important;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedSubmit
  button.loading {
  display: none;
}
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-embedSubmit
  button:hover {
  background-color: #831b93 !important;
}
.ml-subscribe-close {
  width: 30px;
  height: 30px;
  background: url("https://assets.mlcdn.com/ml/images/default/modal_close.png")
    no-repeat;
  background-size: 30px;
  cursor: pointer;
  margin-top: -10px;
  margin-right: -10px;
  position: absolute;
  top: 0;
  right: 0;
}
.ml-error input,
.ml-error textarea,
.ml-error select {
  border-color: red !important;
}

.ml-error .custom-checkbox-radio-list {
  border: 1px solid red !important;
  border-radius: 4px;
  padding: 10px;
}

.ml-error .label-description,
.ml-error .label-description p,
.ml-error .label-description p a,
.ml-error label:first-child {
  color: #ff0000 !important;
}

#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow.ml-error
  .label-description
  p,
#mlb2-9947213.ml-form-embedContainer
  .ml-form-embedWrapper
  .ml-form-embedBody
  .ml-form-checkboxRow.ml-error
  .label-description
  p:first-letter {
  color: #ff0000 !important;
}
@media only screen and (max-width: 400px) {
  .ml-form-embedWrapper.embedDefault,
  .ml-form-embedWrapper.embedPopup {
    width: 100% !important;
  }
  .ml-form-formContent.horozintalForm {
    /* float: left !important; */
  }
  .ml-form-formContent.horozintalForm .ml-form-horizontalRow {
    height: auto !important;
    width: 100% !important;
    /* float: left !important; */
  }
  .ml-form-formContent.horozintalForm
    .ml-form-horizontalRow
    .ml-input-horizontal {
    width: 100% !important;
  }
  .ml-form-formContent.horozintalForm
    .ml-form-horizontalRow
    .ml-input-horizontal
    > div {
    padding-right: 0px !important;
    padding-bottom: 10px;
  }
  .ml-form-formContent.horozintalForm .ml-button-horizontal {
    width: 100% !important;
  }
  .ml-form-formContent.horozintalForm .ml-button-horizontal.labelsOn {
    padding-top: 0px !important;
  }
}
</style>
<script>
export default {
  name: "EmailForm",
  mounted() {
    let Script1 = document.createElement("script");
    Script1.innerHTML = `        function ml_webform_success_9947213() {
      var $ = ml_jQuery || jQuery;
      $('.ml-subscribe-form-9947213 .row-success').show();
      $('.ml-subscribe-form-9947213 .row-form').hide();
    }`;
    document.head.appendChild(Script1);
    let Script2 = document.createElement("script");
    Script2.setAttribute(
      "src",
      "https://groot.mailerlite.com/js/w/webforms.min.js?v1f25ee4b05f240a833e02c19975434a4"
    );
    document.head.appendChild(Script2);
    let Script3 = document.createElement("script");
    Script3.innerHTML = `fetch("https://assets.mailerlite.com/jsonp/720065/forms/106748979174180276/takel")`;
    document.head.appendChild(Script3);
  },
};
</script>
