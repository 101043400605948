<template>
  <div class="teacher-video-container">
    <div class="teacher-video">
      <iframe
        width="100%"
        height="auto"
        src="https://www.youtube.com/embed/fCbt6vfvvuA"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<style>
.teacher-video-container {
  display: flex;
  justify-content: center;
}
.teacher-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.teacher-video::after {
  display: block;
  content: "";
  padding-top: 177.77%;
}

.teacher-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<script>
export default {
  name: "VideoEmbed",
};
</script>
