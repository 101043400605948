<template>
  <footer>
    <span>Connect with me on&nbsp;</span>
    <a class="link" href="https://linkedin.com/in/joeyazoulai">LinkedIn</a>
    <div class="linkedin"><img :src="linkedin" /></div>
  </footer>
</template>

<style>
footer {
  grid-area: footer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2rem;
  background-color: #f8df99;
  color: #181818;
}
.link {
  color: #9d21b0;
  text-decoration: underline;
}
.linkedin {
  height: 90%;
  width: auto;
  margin-left: 1rem;
}
</style>

<script>
import linkedin from "@/assets/linkedin.svg";
export default {
  name: "FooterBar",
  data() {
    return {
      linkedin,
    };
  },
};
</script>
