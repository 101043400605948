<template>
  <main>
    <section class="hero-text">
      <img class="heading" :src="headline" />
    </section>
    <section class="description">
      <p>
        The internet would be a more fun experience if creatives had more say
        about what gets built.
      </p>
      <p>
        My goal with Javascript4Creatives is to demystify coding so you can
        start building that idea you've been sitting on.
      </p>
      <p class="emphasis">Want to hear about my free workshop?</p>
    </section>

    <section class="email-form">
      <EmailForm></EmailForm>
    </section>
    <section class="video-embed">
      <VideoEmbed></VideoEmbed>
    </section>
  </main>
</template>

<script>
import EmailForm from "../components/EmailForm.vue";
import VideoEmbed from "../components/VideoEmbed.vue";
import headline from "@/assets/headline.svg";

export default {
  name: "CoverPage",
  data() {
    return {
      headline,
    };
  },
  components: {
    EmailForm,
    VideoEmbed,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
.emphasis {
  font-weight: 700;
}
/* body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
} */
p {
  margin-bottom: 1rem;
}
p:last-child {
  margin-bottom: 0;
}
.hero-text {
  margin-bottom: 5vh;
  width: 100%;
  height: auto;
}
.heading {
  width: 100%;
  height: auto;
}
main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: auto;
  padding: 0 10vw;
  /* max-width: 1000px; */
}
@media (min-width: 576px) {
  main {
    display: grid;
    height: auto;
    min-height: 100vh;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      ". heading heading ."
      "description description video video"
      "email email video video";
    gap: 5vw;
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  section.hero-text {
    grid-area: heading;
    margin-bottom: 2rem;
    width: 100%;
    /* padding: 2rem; */
    align-self: start;
    margin-bottom: 0;
  }
  section.description {
    grid-area: description;
    /* margin-bottom: 2rem; */
    width: 100%;
    /* padding: 0 2rem; */
    align-self: start;
  }
  section.email-form {
    grid-area: email;
    width: 100%;
    margin-bottom: 3rem;
    align-self: start;
  }
  section.video-embed {
    grid-area: video;
    width: 100%;
    margin-bottom: 5vh;
    align-self: start;
  }
}
@media (min-width: 900px) {
  main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      "heading video"
      "description video"
      "email video";
    gap: 5vw;
    max-width: 1000px;
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  section.hero-text {
    grid-area: heading;
    margin-bottom: 0;
    width: 100%;
    /* padding: 2rem; */
    align-self: start;
  }
  section.description {
    grid-area: description;
    margin-bottom: 0;
    width: 100%;
    /* padding: 0 2rem; */
    align-self: start;
  }
  section.email-form {
    grid-area: email;
    width: 100%;
    margin-bottom: 3rem;
    justify-self: start;
  }
  section.video-embed {
    grid-area: video;
    height: 50%;
    width: auto;
    margin-bottom: 5vh;
    align-self: start;
  }
}

h1 {
  font-size: 3rem;
  line-height: 3.5rem;
}
.signiture {
  font-style: italic;
  text-align: right;
}
.tagline {
  margin-bottom: 0;
}
section.description {
  margin-bottom: 2vh;
}
section.email-form {
  width: 100%;
  margin-bottom: 3rem;
}
section.video-embed {
  width: 100%;
  margin-bottom: 5vh;
}
</style>
