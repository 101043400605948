<template>
  <div class="product-details" v-if="product">
    <CodePen />
  </div>
  <div v-else>
    <NotFoundPage />
  </div>
</template>

<style>
.product-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  grid-area: main;
  overflow-y: scroll;
}
.product-details button {
  margin: 2rem;
  padding: 1rem;
  border-radius: 0;
}
.product-details > * {
  flex: 1 1 0px;
  height: 100%;
}
</style>

<script>
import { products } from "../temp-data";
import NotFoundPage from "./NotFoundPage.vue";
import CodePen from "../components/CodePen.vue";
export default {
  name: "ProductDetailPage",
  data() {
    return {
      product: products.find(
        (product) => product.id === this.$route.params.productId
      ),
    };
  },
  components: {
    NotFoundPage,
    CodePen,
  },
};
</script>
