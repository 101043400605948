<template>
  <NavBar></NavBar>
  <!-- <ProductsList :products="products" /> -->
  <router-view :key="$route.fullPath"></router-view>
  <FooterBar></FooterBar>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400&display=swap");

html,
body {
  font-family: "Noto Serif";
}
#app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: auto;
  /* font-family: "Inclusive Sans", sans-serif; */
  background-color: #181818;
  color: rgb(200, 200, 200);
}
</style>

<script>
// import ProductsList from "./components/ProductsList.vue";
import NavBar from "./components/navbar.vue";
import FooterBar from "./components/FooterBar.vue";
// import { products } from "./temp-data";

export default {
  name: "App",
  components: {
    // ProductsList,
    NavBar,
    FooterBar,
  },
  // data() {
  //   return {
  //     products,
  //   };
  // },
};
</script>
