<template>
  <nav id="myNav">
    <div class="logo"><img :src="logo" /></div>
    <!-- <div class="linkedin"><img :src="linkedin" /></div> -->
  </nav>
</template>

<style>
#myNav {
  height: 7vh;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f8df99;
  margin-bottom: 5vh;
  padding: 0 1rem;
}
.logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.linkedin,
.logo,
img {
  height: 95%;
  width: auto;
}
</style>

<script>
import linkedin from "@/assets/linkedin.svg";
import logo from "@/assets/logo.svg";

export default {
  name: "NavBar",
  data() {
    return {
      linkedin,
      logo,
    };
  },
};
</script>
